.contain {
  width: 28rem !important;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 36px 48px 48px 48px;
  background-color: #f2efee;
  border-radius: 11px;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
}

.login-form {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}

.login-form label {
  display: block;
  margin-bottom: 8px;
}

.login-form input {
  width: 100%;
  padding: 1rem;
  border-radius: 9px;
  border: none;
}

.login-form input:focus {
  outline: none;
  box-shadow: 0 0 0 0.5px var(--bs-lt-red);
}

.text-warning {
  border: 1px solid red !important;
}

.text {
  color: #504a4a;
  text-align: center;
}

.rightText {
  margin-left: auto;
}

.centerText {
  text-align: center;
}

.btn {
  border: none !important;
}
