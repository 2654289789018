.paginationBtn {
    color: var(--bs-lt-red);
    cursor: pointer;
}

.paginationDisableBtn {
    color: var(--bs-lt-grey);
}

.paginationDisableBtn :active {
    color: var(--bs-lt-grey) !important;
}

.eventlistheight {
    height: auto;
    min-height: 39rem;
}

@media (max-width: 765px) {
    .eventlistheight {
        min-height: 34rem;
    }
}