@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Josefin+Sans:wght@400;700&display=swap");
:root {
  --bs-primary: #2179FD;
  --bs-lt-red: #ff8282;
  --bs-red: #FF5C5C;
  --bs-dk-red: #ed2424;
  --bs-redwood: #b1504f;
  --bs-lt-grey: #dddddd;
  --bs-grey: #c5c5c5;
  --bs-dk-grey: #606060;
  --bs-white: #fff;
  --bs-lt-black: #2a2a2a;
  --bs-black: #1e1e1e;
  --bs-dk-black: #0f0f0f;
}

img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

body {
  min-height: 100vh;
  background-color: var(--bs-black);
  color: var(--bs-white);
  font-family: "Josefin Sans", sans-serif;
  font-size: 16px;
}
@media (max-width: 576px) {
  body {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5 {
  font-family: "Inter", sans-serif;
  font-weight: 900;
  margin-bottom: 0.3em;
}

h1 {
  font-size: 3.5em;
}
@media (max-width: 991px) {
  h1 {
    font-size: 1.8em;
  }
}

h2 {
  font-size: 2.5em;
}
@media (max-width: 991px) {
  h2 {
    font-size: 1.55em;
  }
}

h3 {
  font-size: 1.9em;
}
@media (max-width: 991px) {
  h3 {
    font-size: 1.3em;
  }
}

h4 {
  font-size: 1.3em;
}
@media (max-width: 991px) {
  h4 {
    font-size: 1.15em;
  }
}

h5 {
  font-size: 1.1em;
}
@media (max-width: 991px) {
  h5 {
    font-size: 1em;
  }
}

hr {
  border-top: 2px solid var(--bs-lt-black);
  opacity: 1;
  margin: 2em 0;
}
@media (max-width: 991px) {
  hr {
    margin: 2em 0;
  }
}
@media (max-width: 576px) {
  hr {
    margin: 1.4em 0;
  }
}

a {
  color: var(--bs-lt-red);
  text-decoration: none;
  transition: 0.15s ease-in-out;
}
a.active, a:hover, a:focus, a :active {
  color: var(--bs-red);
}

.white {
  color: var(--bs-white) !important;
}

p {
  line-height: 1.6em;
  max-width: 700px;
}

.hamburger {
  display: inline-block;
  cursor: pointer;
}
.hamburger .bar-line {
  border-radius: 10px;
}
.hamburger .bar1, .hamburger .bar2, .hamburger .bar3 {
  width: 27px;
  height: 4px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.2s ease-in-out;
  margin-left: auto;
}
.hamburger .bar1 {
  width: 27px;
}
.hamburger .bar2 {
  width: 21px;
}
.hamburger .bar3 {
  width: 24px;
}
.hamburger.change .bar1 {
  width: 27px;
  transform: translate(0, 9px) rotate(135deg);
}
.hamburger.change .bar2 {
  width: 27px;
  opacity: 0;
}
.hamburger.change .bar3 {
  width: 27px;
  transform: translate(0, -9px) rotate(-135deg);
}

.navbar-brand {
  text-align: center;
  font-size: 17px;
}
@media (max-width: 991px) {
  .navbar-brand {
    text-align: left;
    font-size: 17px;
  }
}
.navbar-brand img {
  width: 85px;
}
@media (max-width: 991px) {
  .navbar-brand img {
    width: 50px;
  }
}
.navbar-brand a {
  color: var(--bs-white);
}

.form-check-input:checked {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 92, 92, 0.2509803922);
}
.form-check-input.order-checkbox {
  margin-bottom: 6px;
}

input.form-check-input[type=radio] + .form-check-label, input.form-check-input + .form-check-label {
  cursor: pointer;
}

.admin-left-column {
  border-right: 1px solid #141414;
  background: #161616;
  box-shadow: 5px 2px 22px rgba(0, 0, 0, 0.45);
  min-height: 100vh;
  max-width: 300px;
}
@media (max-width: 991px) {
  .admin-left-column {
    border-right: initial;
    background: initial;
    box-shadow: none;
    min-height: initial;
    max-width: initial;
  }
}

.input-block {
  border: 2px solid var(--bs-grey);
  border-radius: 8px;
  margin-top: 25px;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2px;
  transition: 0.15s ease-in-out;
  width: 100%;
  /*** DISABLED ***/
}
.input-block label {
  background: var(--bs-black);
  color: var(--bs-grey);
  display: inline-flex;
  padding: 0px 5px;
  font-size: 16px;
  margin-top: -10px;
  margin-bottom: -3px;
  margin-left: 8px;
  position: relative;
  transition: 0.15s ease-in-out;
  z-index: 10;
}
.input-block label:after {
  content: "";
  background: var(--bs-black);
  bottom: 0;
  left: 0;
  position: absolute;
  transition: 0.15s ease-in-out;
  height: 50%;
  width: 100%;
  z-index: -1;
}
.input-block input, .input-block textarea {
  border: none;
  border-radius: 8px;
  background-color: var(--bs-black);
  color: var(--bs-grey);
  transition: 0.15s ease-in-out;
  font-size: 0.9em;
}
@media (max-width: 576px) {
  .input-block input, .input-block textarea {
    padding: 0.25rem 0.5rem;
  }
}
.input-block input:focus, .input-block textarea:focus {
  border: none;
  box-shadow: none;
  background: var(--bs-white);
  outline: 0;
}
.input-block:focus-within {
  border-color: var(--bs-white);
  outline: 0;
  background-color: var(--bs-black);
}
.input-block:focus-within input,
.input-block:focus-within textarea {
  background-color: var(--bs-black);
  color: var(--bs-white);
}
.input-block:focus-within label {
  color: var(--bs-white);
  margin-top: -9px;
  padding-bottom: 2px;
  font-size: 14px;
}
.input-block:focus-within label:after {
  background: var(--bs-black);
}
.input-block:focus-within .input-group .input-group-text {
  color: var(--bs-white);
}
.input-block .input-group .input-group-text {
  background: none;
  border: none;
  color: var(--bs-grey);
  margin-top: 1px;
  padding: 0;
  padding-left: 12px;
  align-self: center;
  z-index: 6;
  transition: 0.15s ease-in-out;
}
@media (max-width: 576px) {
  .input-block .input-group .input-group-text {
    font-size: 14px;
    padding-left: 0.5rem;
  }
}
.input-block .input-group .input-group-text + .form-control {
  padding-left: 5px;
}
.input-block.disabled {
  border-color: var(--bs-dk-grey);
  padding-top: 1px;
}
.input-block.disabled label {
  color: var(--bs-dk-grey);
}
.input-block.disabled input {
  background: var(--bs-black);
  color: var(--bs-dk-grey);
}
.input-block.disabled .input-group-text {
  color: var(--bs-dk-grey);
}

.attendee-information-table {
  margin-top: 10px;
  margin-bottom: 40px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
}
.attendee-information-table.attendee-information-table > .row {
  border-bottom: 1px dashed var(--bs-dk-grey);
  padding: 10px 0;
}
.attendee-information-table.attendee-information-table > .row > .col-5 > .row {
  height: 100%;
  display: flex;
  align-items: center;
}
.attendee-information-table .move {
  display: flex;
  align-items: center;
}
.attendee-information-table .move a {
  padding: 7px;
  padding-left: 0px;
}

.sidebar-nav {
  position: sticky;
  top: 20px;
}
@media (max-width: 991px) {
  .sidebar-nav {
    position: initial;
    top: 0px;
  }
}
.sidebar-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 991px) {
  .sidebar-nav ul {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--bs-black);
    z-index: 100;
    padding: 0 12px;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: 0.15s ease-in-out;
  }
}
.sidebar-nav ul.show {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}
.sidebar-nav ul li a {
  text-transform: capitalize;
  border-radius: 10px;
  display: flex;
  width: 100%;
  padding: 12px 10px;
  text-decoration: none;
  color: var(--bs-white);
  margin-bottom: 7px;
  align-items: center;
  transition: 0.1s ease-in-out;
  font-size: 1.2em;
  cursor: pointer;
}
@media (max-width: 991px) {
  .sidebar-nav ul li a {
    padding: 10px 0px;
  }
}
.sidebar-nav ul li a.active, .sidebar-nav ul li a:hover, .sidebar-nav ul li a:focus, .sidebar-nav ul li a :active {
  background-color: var(--bs-dk-black);
}
.sidebar-nav ul li a .fa-stack {
  color: var(--bs-dk-black);
  margin-right: 6px;
}
.sidebar-nav ul li a .fa-stack .fa-inverse {
  color: var(--bs-lt-red);
}
.sidebar-nav ul li a .title {
  font-size: 0.85em;
  font-weight: 700;
  padding-top: 4px;
}
.sidebar-nav ul:nth-of-type(2) {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid var(--bs-dk-grey);
}

.home-block {
  background: var(--bs-dk-black);
  border-radius: 12px;
  margin-bottom: 20px;
  padding: 15px;
  min-height: 360px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
@media (max-width: 991px) {
  .home-block {
    min-height: 370px;
  }
}
@media (max-width: 767px) {
  .home-block {
    min-height: 250px;
  }
}
@media (max-width: 576px) {
  .home-block {
    margin-bottom: 12px;
  }
}
.home-block:before {
  display: none;
}
.home-block .svg-inline--fa:not(.icon) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 48%;
  color: rgba(30, 30, 30, 0.3882352941);
  z-index: 1;
  pointer-events: none;
  transition: 0.2s ease-in-out;
}
.home-block:hover .svg-inline--fa:not(.icon), .home-block:focus .svg-inline--fa:not(.icon), .home-block :active .svg-inline--fa:not(.icon) {
  color: rgba(45, 45, 45, 0.3882352941);
  transform: rotate(3deg) scale(1.2);
}
.home-block.revenue::before {
  font: var(--fa-font-solid);
  content: "\f81d";
}
.home-block.tickets-sold::before {
  font: var(--fa-font-solid);
  content: "\f145";
}
.home-block.add-ons-sold::before {
  font: var(--fa-font-solid);
  content: "\f890";
}
.home-block.total-views::before {
  font: var(--fa-font-solid);
  content: "\f643";
}
.home-block.shareable::before {
  font: var(--fa-font-solid);
  content: "\f1e0";
}
.home-block .content {
  z-index: 2;
  margin-bottom: auto;
}
.home-block .content .title {
  font-weight: 700;
  font-size: 0.9em;
}
.home-block .content .number {
  font-size: 1.5em;
  font-family: "Inter", sans-serif;
}
.home-block .share-block {
  display: flex;
  margin-top: -5px;
  align-items: center;
}
.home-block .share-block .url span {
  margin-left: 2px;
  padding: 10px 5px;
  background-color: var(--bs-lt-black);
  border-radius: 5px;
  outline: 0;
}
.home-block .share-block .icon {
  display: flex;
  align-items: center;
  margin-left: 7px;
  font-size: 1.2em;
}
.home-block .input-block {
  margin-right: 8px;
  border-radius: 8px;
  background-color: transparent;
  /*** DISABLED ***/
}
.home-block .input-block label {
  background: var(--bs-dk-black);
  color: var(--bs-grey);
}
.home-block .input-block label:after {
  background: var(--bs-dk-black);
}
.home-block .input-block input, .home-block .input-block textarea {
  border-radius: 8px;
  background-color: transparent;
  color: var(--bs-grey);
}
.home-block .input-block input:focus, .home-block .input-block textarea:focus {
  background: var(--bs-white);
}
.home-block .input-block:focus-within {
  border-color: var(--bs-white);
  background-color: var(--bs-dk-black);
}
.home-block .input-block:focus-within input,
.home-block .input-block:focus-within textarea {
  background-color: var(--bs-dk-black);
  color: var(--bs-white);
}
.home-block .input-block:focus-within label {
  color: var(--bs-white);
}
.home-block .input-block:focus-within label:after {
  background: var(--bs-dk-black);
}
.home-block .input-block.disabled {
  border-color: var(--bs-dk-grey);
  background: transparent;
}
.home-block .input-block.disabled label {
  color: var(--bs-dk-grey);
}
.home-block .input-block.disabled input {
  background: transparent;
  color: var(--bs-dk-grey);
}
.home-block .social-icons {
  font-size: 1.3em;
}
.home-block .social-icons a {
  margin-right: 5px;
  padding: 4px;
  display: inline-block;
}

.chart-container {
  z-index: 2;
  padding-bottom: 5px;
}
.chart-container .legend {
  font-size: 0.9em;
}
.chart-container .legend .marker {
  content: " ";
  width: 15px;
  height: 15px;
  display: inline-block;
  border-radius: 50%;
  margin-bottom: -3px;
}
.chart-container .legend .complete .marker {
  background: var(--bs-red);
}
.chart-container .legend .incomplete .marker {
  background: var(--bs-dk-grey);
}
.chart-container canvas {
  height: 100% !important;
  min-width: 100% !important;
}

.event-sidebar-nav {
  min-height: 100vh;
  padding-top: 40px;
  background-color: var(--bs-dk-black);
  box-shadow: 5px 2px 22px rgba(0, 0, 0, 0.45);
  max-width: 300px;
}
@media (max-width: 991px) {
  .event-sidebar-nav {
    min-height: initial;
    padding: 6px 12px 4px 12px;
    box-shadow: none;
    max-width: initial;
  }
}
.event-sidebar-nav .event-title {
  font-weight: 700;
  font-size: 1.1em;
  margin: 0;
  line-height: 1.2;
}
.event-sidebar-nav .eventMenu {
  font-size: 1.3em;
  display: flex;
}
.event-sidebar-nav .link {
  text-decoration: none;
  color: #fff;
  background-color: var(--bs-dk-black);
  width: 100%;
  display: block;
  font-size: 1em;
  display: flex;
  align-items: center;
  padding: 10px 4px;
  text-transform: capitalize;
  border-radius: 10px;
  transition: 0.1s ease-in-out;
  margin-bottom: 7px;
}
.event-sidebar-nav .link.active, .event-sidebar-nav .link:hover, .event-sidebar-nav .link:focus, .event-sidebar-nav .link :active {
  background-color: var(--bs-black);
}
.event-sidebar-nav .link .fa-stack {
  color: var(--bs-black);
  margin-right: 4px;
}
.event-sidebar-nav .link .fa-stack .fa-inverse {
  color: var(--bs-lt-red);
}
.event-sidebar-nav .link .title {
  font-size: 0.85em;
  font-weight: 700;
  padding-top: 4px;
}
.event-sidebar-nav .accordion-menu {
  margin-top: 15px;
  margin-bottom: 40px;
}
@media (max-width: 991px) {
  .event-sidebar-nav .accordion-menu {
    margin-top: 0px;
    margin-bottom: 0px;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    max-height: 0px;
    transition: 0.15s ease-in-out;
  }
  .event-sidebar-nav .accordion-menu.show {
    margin-top: 10px;
    visibility: visible;
    opacity: 1;
    z-index: 5;
    max-height: 1600px;
  }
}
.event-sidebar-nav .accordion-menu .accordion-item {
  background-color: initial;
  border: none;
  margin-top: 10px;
}
.event-sidebar-nav .accordion-menu .accordion-button {
  background-color: initial;
  color: var(--bs-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  box-shadow: none;
  font-family: "Josefin Sans", sans-serif;
  padding: 8px 0px;
}
.event-sidebar-nav .accordion-menu .accordion-button:after {
  display: none;
  font: var(--fa-font-solid);
  content: "\f107";
}
.event-sidebar-nav .accordion-menu .accordion-button svg {
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}
.event-sidebar-nav .accordion-menu .accordion-button.collapsed svg {
  transform: rotate(180deg);
}
.event-sidebar-nav .accordion-menu .accordion-body {
  color: var(--bs-white);
  padding: 0;
  padding-left: 12px;
  margin: 7px 0;
  border-left: 2px solid var(--bs-lt-red);
  margin-left: 10px;
}
@media (max-width: 991px) {
  .event-sidebar-nav .accordion-menu .accordion-body {
    margin-left: 5px;
  }
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu a {
  text-decoration: none;
  color: #fff;
  background-color: var(--bs-dk-black);
  width: 100%;
  display: block;
  font-size: 1em;
  display: flex;
  align-items: center;
  padding: 6px 4px;
  text-transform: capitalize;
  border-radius: 10px;
  transition: 0.1s ease-in-out;
  margin-bottom: 5px;
  cursor: pointer;
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu a.active, .event-sidebar-nav .accordion-menu .accordion-body .sub-menu a:hover, .event-sidebar-nav .accordion-menu .accordion-body .sub-menu a:focus, .event-sidebar-nav .accordion-menu .accordion-body .sub-menu a :active {
  background-color: var(--bs-black);
  color: #fff;
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu a .fa-stack {
  color: var(--bs-black);
  margin-right: 4px;
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu a .fa-stack .fa-inverse {
  color: var(--bs-lt-red);
}
.event-sidebar-nav .accordion-menu .accordion-body .sub-menu a .title {
  font-size: 0.85em;
  font-weight: 700;
  padding-top: 4px;
}

.ticket-menu ul {
  list-style: none;
  overflow: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}
.ticket-menu ul li {
  display: inline-block;
}
.ticket-menu ul li a {
  background-color: var(--bs-lt-black);
  color: var(--bs-white);
  border-radius: 8px;
  margin-right: 12px;
  font-size: 1em;
  line-height: 1.3;
  text-decoration: none;
  text-align: center;
  min-height: 100px;
  min-width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1.6;
  transition: 0.15s ease-in-out;
}
@media (max-width: 991px) {
  .ticket-menu ul li a {
    min-height: 80px;
    min-width: 80px;
    font-size: 0.9em;
  }
}
.ticket-menu ul li a.active, .ticket-menu ul li a:hover, .ticket-menu ul li a:focus, .ticket-menu ul li a :active {
  background-color: var(--bs-dk-black);
  color: #fff;
}
.ticket-menu ul li a .icon {
  display: block;
}

.board {
  padding-top: 39px;
  padding-left: 40px;
  padding-bottom: 200px;
  max-width: 1700px;
}
@media (max-width: 991px) {
  .board {
    padding-top: 30px;
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    padding-bottom: 70px;
  }
}
.board .small-container {
  max-width: 900px;
}

.event-sidebar-nav + .board {
  max-width: 1000px;
}

.block {
  margin-top: 20px;
  padding: 25px;
  border: 1px solid #1a1a1a;
  border-radius: 18px;
  background-color: var(--bs-lt-black);
}
.block p:last-child {
  margin-bottom: 0px;
}
.block .input-block {
  background: var(--bs-lt-black);
}
.block .input-block label {
  background: var(--bs-lt-black);
}
.block .input-block label:after {
  background: var(--bs-lt-black);
}
.block .input-block input {
  background-color: var(--bs-lt-black);
}
@media (max-width: 576px) {
  .block {
    margin-top: 12px;
    padding: 25px 20px;
  }
}
.block .date-block {
  min-width: 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.block .date-block .day {
  font-size: 1.5em;
  color: var(--bs-white) !important;
}
.block .date-block .month {
  font-size: 0.9em;
  margin-top: -10px;
  font-weight: 700;
  color: var(--bs-lt-red);
}
.block .event-title {
  color: var(--bs-white) !important;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 1.3em;
}
.block .multiple-select-container .select-btn.open {
  background-color: var(--bs-lt-black);
}
.block .multiple-select-container .btn-label, .block .multiple-select-container .list-items {
  background-color: var(--bs-lt-black);
}

.btn {
  padding: 18px 20px 13px 20px;
  font-size: 13px;
  line-height: 1em;
  font-weight: 700;
  border: none;
  transition: 0.2s ease-in-out;
}
@media (max-width: 576px) {
  .btn {
    padding: 16px 16px 12px 16px;
  }
}
.btn.primary-btn {
  color: var(--bs-black);
  background-color: var(--bs-lt-red);
}
.btn.primary-btn:hover, .btn.primary-btn:focus, .btn.primary-btn :active {
  color: var(--bs-black);
  background-color: var(--bs-red);
}
.btn.secondary-btn {
  color: var(--bs-white);
  background-color: var(--bs-lt-black);
}
.btn.secondary-btn:hover, .btn.secondary-btn:focus, .btn.secondary-btn :active {
  color: var(--bs-white);
  background-color: var(--bs-dk-black);
}

.pills {
  margin-top: 25px;
}
.pills .pill {
  background: var(--bs-lt-red);
  padding: 5px 9px 5px 15px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  display: inline-flex;
  font-size: 0.9em;
  font-weight: 700;
  line-height: 1em;
}
.pills .pill .text {
  display: flex;
  align-self: center;
  line-height: 1.1em;
  padding-top: 2px;
  color: var(--bs-black);
}
.pills .pill .mark-close {
  padding: 6px;
  display: flex;
  color: var(--bs-black);
}

.form-switch .form-check-label {
  padding-top: 3px;
  font-size: 0.95em;
}
.form-switch .form-check-input {
  margin-top: 5px;
}

.ticket-table {
  padding: 10px;
  font-size: 15px;
  /*** ORDER DETAILS LIST***/
}
@media (max-width: 991px) {
  .ticket-table {
    font-size: 13px;
  }
}
@media (max-width: 576px) {
  .ticket-table {
    font-size: 12px;
  }
}
.ticket-table > .heading {
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
  padding: 13px 7px;
  background-color: var(--bs-dk-black);
  border-radius: 12px;
  border-bottom: 1px solid #222222;
  border-right: 1px solid #3e3e3e;
  font-size: 13px;
  letter-spacing: 0.02em;
}
@media (max-width: 991px) {
  .ticket-table > .heading {
    display: none;
  }
}
@media (max-width: 576px) {
  .ticket-table > .heading {
    font-size: 11px;
  }
}
@media (max-width: 991px) {
  .ticket-table.attendee-list {
    overflow-x: scroll;
  }
  .ticket-table.attendee-list > .heading, .ticket-table.attendee-list .event-row {
    min-width: 950px;
  }
}
@media (max-width: 991px) {
  .ticket-table.order-details-list {
    overflow-x: scroll;
  }
  .ticket-table.order-details-list > .heading, .ticket-table.order-details-list .event-row {
    min-width: 700px;
  }
}
.ticket-table .event-row {
  padding: 13px 7px;
  background-color: var(--bs-lt-black);
  border-radius: 12px;
  margin-top: 10px;
  border-bottom: 1px solid #222222;
  border-right: 1px solid #3e3e3e;
  transition: 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .ticket-table .event-row {
    padding: 20px 5px;
  }
}
.ticket-table .event-row > .col-10 {
  cursor: pointer;
}
.ticket-table .event-row:hover {
  background-color: var(--bs-dk-black);
  border-right: 1px solid #202020;
  border-bottom: 1px solid #202020;
}
.ticket-table .event-row .date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
}
.ticket-table .event-row .date .day {
  font-size: 1.8em;
  display: block;
  color: var(--bs-white) !important;
}
.ticket-table .event-row .date .month {
  margin-top: -10px;
  display: block;
  font-weight: 700;
  color: var(--bs-lt-red);
}
.ticket-table .event-row .thumbnail {
  display: flex;
  align-items: center;
}
.ticket-table .event-row .thumbnail img {
  border-radius: 12px;
  min-height: 90px;
  max-height: 90px;
}
.ticket-table .event-row .event-info {
  display: flex;
  flex-direction: column;
  font-size: 0.75em;
  justify-content: center;
  color: var(--bs-white) !important;
}
@media (max-width: 991px) {
  .ticket-table .event-row .event-info {
    font-size: 0.9em;
  }
}
.ticket-table .event-row .event-info .heading {
  font-weight: 700;
  font-size: 1.3em;
  text-transform: capitalize;
  margin-bottom: 5px;
  line-height: 1.4em;
  color: var(--bs-white) !important;
}
.ticket-table .event-row .event-info .location {
  color: var(--bs-white) !important;
}
.ticket-table .event-row .event-info .location .location-name {
  font-weight: 700;
}
.ticket-table .event-row .event-info .full-date {
  color: var(--bs-white) !important;
}
.ticket-table .event-row .revenue-info, .ticket-table .event-row .status-info {
  line-height: 1em;
  color: var(--bs-white) !important;
  margin-top: 9px;
}
.ticket-table .event-row .sold-info {
  color: var(--bs-white) !important;
}
.ticket-table .event-row .mobile-event-block .info strong {
  font-size: 1.22em;
}
.ticket-table .event-row .mobile-event-block .d-flex .info {
  margin-right: 17px;
  padding-right: 17px;
  border-right: 1px solid var(--bs-white);
  display: grid;
}
@media (max-width: 576px) {
  .ticket-table .event-row .mobile-event-block .d-flex .info {
    margin-right: 13px;
    padding-right: 13px;
  }
}
.ticket-table .event-row .mobile-event-block .d-flex .info:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: 0;
}
.ticket-table .event-row .mobile-event-block .d-flex .info .heading {
  margin-bottom: 1px;
}
.ticket-table .event-row .ticket-type-name {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.progress {
  --bs-progress-bar-bg: var(--bs-red);
}

.dropdown {
  position: relative;
  display: flex;
  /***** remove select on dropdown select ****/
}
.dropdown .dropdown-button {
  transition: 0.2s ease-in-out;
  color: var(--bs-lt-red);
  background: none;
  border: 0;
  text-align: left;
  font-size: 0.9em;
}
.dropdown .dropdown-button:hover, .dropdown .dropdown-button:focus, .dropdown .dropdown-button :active {
  color: var(--bs-red);
}
.dropdown .dropdown-button .fa-stack {
  pointer-events: none;
}
.dropdown .dropdown-button .fa-stack .fa-ellipsis-vertical {
  color: var(--bs-black);
}
.dropdown .dropdown-button svg {
  pointer-events: none;
}
.dropdown.select .dropdown-button {
  color: var(--bs-lt-grey);
  padding: 6px 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14.4px;
  /****** FONT AWESOME ******/
}
@media (max-width: 576px) {
  .dropdown.select .dropdown-button {
    padding: 0.25rem 0.75rem;
  }
}
.dropdown.select .dropdown-button:hover, .dropdown.select .dropdown-button:focus, .dropdown.select .dropdown-button :active {
  color: var(--bs-white);
}
.dropdown.select .dropdown-button + .dropdown-menu {
  left: 0px;
  right: initial;
}
.dropdown.select .dropdown-button::after {
  display: none;
}
.dropdown.select .dropdown-button::after {
  font: var(--fa-font-solid);
  content: "\f107";
}
.dropdown.select svg {
  transition: 0.2s ease-in-out;
}
.dropdown .select-block {
  width: 100%;
  position: relative;
}
.dropdown .select-block::after {
  display: none;
}
.dropdown .select-block::after {
  font: var(--fa-font-solid);
  content: "\f0dc";
}
.dropdown .select-block svg {
  color: var(--bs-lt-grey);
  position: absolute;
  right: 13px;
  top: 8px;
  pointer-events: none;
  transition: 0.15s ease-in-out;
  font-size: 0.9em;
}
.dropdown .select-block select {
  width: 100%;
  padding: 5px 15px;
  outline: 0;
  background: var(--bs-black);
  color: var(--bs-lt-grey);
  border: 0;
  transition: 0.15s ease-in-out;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}
.dropdown .select-block select:focus {
  color: var(--bs-white);
}
.dropdown .select-block select:focus + svg {
  color: var(--bs-white);
}
.dropdown.active svg {
  transform: rotate(-180deg);
}
.dropdown.active > .dropdown-button + .dropdown-menu {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
}
.dropdown .dropdown-menu {
  position: absolute;
  right: 0px;
  top: calc(100% + 0.25rem);
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transform: translateY(-10px);
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  background: none;
  display: grid;
}
.dropdown .dropdown-links {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 215px;
  border-radius: 10px;
  /***** SCROLL BAR ****/
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.dropdown .dropdown-links::-webkit-scrollbar {
  width: 7px;
  border-radius: 7px;
}
.dropdown .dropdown-links::-webkit-scrollbar-track {
  background: var(--bs-grey);
  border-radius: 5px;
}
.dropdown .dropdown-links::-webkit-scrollbar-thumb {
  background: var(--bs-dk-grey);
  border-radius: 5px;
}
.dropdown .dropdown-links::-webkit-scrollbar-thumb:hover {
  background: var(--bs-black);
}
.dropdown .dropdown-links .link {
  background: var(--bs-lt-black);
  color: var(--bs-white);
  border: none;
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  padding: 12px 15px;
  padding-top: 15px;
  transition: 0.1s ease-in-out;
  line-height: 1.25;
}
.dropdown .dropdown-links .link:hover, .dropdown .dropdown-links .link:focus, .dropdown .dropdown-links .link :active {
  background: var(--bs-black);
}
.dropdown .dropdown-links .link:first-child {
  border-top-left-radius: 10px;
}
.dropdown .dropdown-links .link:last-child {
  border-bottom-left-radius: 10px;
}

/******* ORDER PAGE ******/
.btn-group {
  margin-right: 8px;
}
.btn-group .btn {
  border: 2px solid var(--bs-grey);
  padding: 16px;
  color: var(--bs-white);
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  font-size: 1em;
}
.btn-group .order-checkbox {
  margin: 0;
  pointer-events: none;
}
.btn-group span {
  margin-bottom: -2px;
  margin-top: 2px;
  margin-left: 6px;
  font-size: 0.95em;
}

@media (max-width: 991px) {
  .order-filters-modal {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    padding: 0 15px;
    background-color: rgba(0, 0, 0, 0.5294117647);
  }
  .order-filters-modal.show {
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }
  .order-filters-modal .content {
    background-color: var(--bs-black);
    position: relative;
    top: 46%;
    transform: translateY(-50%);
    border-radius: 6px;
    padding: 20px 15px;
  }
}

.report-filters {
  position: absolute;
  top: 52px;
  left: 0;
  width: 290px;
  background-color: var(--bs-lt-black);
  border-radius: 12px;
  padding: 8px;
  padding-top: 0px;
  transition: 0.15s ease-in-out;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}
.report-filters.active {
  visibility: visible;
  pointer-events: initial;
  opacity: 1;
  z-index: 100;
}
.report-filters .block {
  margin-top: 8px;
  background-color: var(--bs-black);
  padding: 15px;
  text-align: center;
  aspect-ratio: 16/12;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  border-radius: 12px;
}
.report-filters .block.active, .report-filters .block:hover, .report-filters .block:focus, .report-filters .block :active {
  background-color: var(--bs-dk-black);
}
.report-filters .block .icon {
  font-size: 1.6em;
  margin-bottom: 2px;
}
.report-filters .block .title {
  font-size: 13px;
}

.multiple-select-container {
  position: relative;
  margin-top: 20px;
}
.multiple-select-container .list-items {
  position: absolute;
  top: 40px;
  left: 0;
}

.select-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 12px 7px 15px;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid var(--bs-grey);
  color: var(--bs-grey);
  transition: 0.15s ease-in-out;
}
.select-btn .btn-label {
  position: absolute;
  top: -11px;
  left: 10px;
  padding: 0px 5px;
  background: var(--bs-black);
  transition: 0.15s ease-in-out;
}
.select-btn.open {
  border-color: var(--bs-white);
  outline: 0;
  background-color: var(--bs-black);
}
.select-btn.open .btn-label {
  color: var(--bs-white);
  top: -9px;
  font-size: 14px;
}
.select-btn.open ~ .list-items {
  display: block;
  width: 100%;
  z-index: 99;
}

.list-items {
  position: relative;
  margin-top: 15px;
  border-radius: 10px;
  padding: 0px;
  background-color: var(--bs-black);
  display: none;
  border: 2px solid var(--bs-grey);
  transition: all 0.2s ease-in-out;
  z-index: -1;
}
.list-items:hover {
  border: 2px solid var(--bs-white);
}
.list-items .item {
  display: flex;
  align-items: center;
  list-style: none;
  height: 45px;
  cursor: pointer;
  transition: 0.3s;
  padding: 0 15px;
  border-radius: 8px;
}
.list-items .item .item-text {
  color: var(--bs-grey);
  font-size: 14px;
  margin-top: 1px;
}
.list-items .item:hover {
  background-color: var(--bs-dk-black);
}
.list-items .item:hover .item-text {
  color: var(--bs-white);
}
.list-items .item .checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  margin-right: 6px;
  border: 1.5px solid #c0c0c0;
  transition: all 0.2s ease-in-out;
}
.list-items .item .checkbox .check-icon {
  color: #fff;
  font-size: 11px;
  transform: scale(0);
  transition: all 0.2s ease-in-out;
}
.list-items .item.checked {
  background-color: var(--bs-dk-black);
}
.list-items .item.checked .checkbox {
  background-color: var(--bs-red);
  border-color: var(--bs-red);
}
.list-items .item.checked .check-icon {
  transform: scale(1);
}

/******** CREATE EVENT ********/
.ai-modal .modal-dialog {
  padding: 8px;
}
.ai-modal .modal-dialog .modal-body {
  padding-top: 55px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}
.ai-modal .modal-dialog .modal-body .close {
  position: absolute;
  top: 20px;
  right: 22px;
  font-size: 30px;
}
@media (max-width: 576px) {
  .ai-modal .modal-dialog .modal-body .close {
    font-size: 28px;
  }
}
.ai-modal .modal-dialog .modal-body h3 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 2px;
}
@media (max-width: 991px) {
  .ai-modal .modal-dialog .modal-body h3 {
    font-size: 1.7em;
  }
}
.ai-modal .modal-dialog .modal-body .ai-progress {
  position: absolute;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--bs-grey);
  pointer-events: none;
  font-size: 13px;
  letter-spacing: 3px;
  font-weight: 700;
  opacity: 0.85;
}

.checkbox-group {
  margin-top: 12px;
}
.checkbox-group .btn-check ~ label, .checkbox-group .primary-btn {
  padding: 17px 18px 14px 18px;
  line-height: 1em;
  background-color: var(--bs-lt-black);
  border: 2px solid var(--bs-grey);
  color: var(--bs-grey);
  width: 100%;
}
@media (max-width: 576px) {
  .checkbox-group .btn-check ~ label, .checkbox-group .primary-btn {
    padding: 17px 10px 14px 10px;
  }
}
.checkbox-group .btn-check ~ label:hover, .checkbox-group .btn-check ~ label:focus, .checkbox-group .btn-check ~ label:active, .checkbox-group .primary-btn:hover, .checkbox-group .primary-btn:focus, .checkbox-group .primary-btn:active {
  background-color: var(--bs-red);
  color: var(--bs-dk-black);
  border: 2px solid var(--bs-lt-red);
}
.checkbox-group .btn-check:checked ~ label {
  background-color: var(--bs-lt-red);
  border: 2px solid var(--bs-lt-red);
  color: var(--bs-dk-black);
}

.google-maps {
  position: relative;
  padding-bottom: 45%;
  height: 0;
  overflow: hidden;
  border-radius: 12px;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.banner {
  border-radius: 10px;
  background-color: var(--bs-lt-black);
  margin-top: 30px;
  margin-bottom: 16px;
  border: 2px dashed var(--bs-dk-grey);
  cursor: pointer;
  transition: 0.15s ease-in-out;
  color: var(--bs-grey);
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  aspect-ratio: 16/8;
  overflow: hidden;
  width: 100%;
}
.banner .content {
  text-align: center;
  font-size: 1.2em;
}
.banner .content img {
  border-radius: 10px;
}
.banner .content .fa-image {
  font-size: 4em;
  margin-bottom: 10px;
}
.banner:hover {
  color: var(--bs-white);
  border: 2px dashed var(--bs-white);
}

.summary-block {
  display: flex;
}
.summary-block .tools {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-right: 10px;
}
.summary-block .tools .icon {
  margin-top: 8px;
  font-size: 1.1em;
  background-color: var(--bs-lt-black);
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid var(--bs-dk-grey);
  transition: 0.15s ease-in-out;
  color: var(--bs-grey);
}
.summary-block .tools .icon.active, .summary-block .tools .icon:hover, .summary-block .tools .icon:focus {
  border: 1px solid var(--bs-white);
  color: var(--bs-white);
}
@media (max-width: 576px) {
  .summary-block .tools .icon {
    font-size: 1em;
    height: 37px;
    width: 37px;
  }
}
.summary-block .embed-video {
  width: 100%;
}
.summary-block .embed-video .youtube-video {
  aspect-ratio: 16/9;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  width: 100% !important;
  height: initial !important;
}

.venue-location li {
  cursor: pointer;
}

.description-buttons {
  padding: 20px 26px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: var(--bs-lt-black);
  color: var(--bs-grey);
  border: 1px solid var(--bs-dk-grey);
  border-radius: 10px;
  transition: 0.15s ease-in-out;
  margin-bottom: 15px;
  text-decoration: none;
  flex-wrap: wrap;
  margin: 6px;
  cursor: pointer;
}
.description-buttons .fa-solid-text-circle-plus, .description-buttons .fa-solid-image-circle-plus, .description-buttons .fa-solid-video-circle-plus {
  font-size: 1.8em;
  margin-bottom: 10px;
}
.description-buttons.active, .description-buttons:hover, .description-buttons:focus, .description-buttons:active {
  color: var(--bs-white);
  border: 1px solid var(--bs-white);
}
@media (max-width: 576px) {
  .description-buttons {
    font-size: 0.88em;
    padding: 16px 18px;
    margin: 1px;
  }
}

.group-heading {
  margin-top: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  border-bottom: 2px dashed var(--bs-dk-grey);
  margin-bottom: 18px;
}

.group-section {
  margin-top: 10px;
}
.group-section .group-row {
  background-color: var(--bs-lt-black);
  color: var(--bs-white);
  border: 2px solid var(--bs-lt-black);
  padding: 18px 15px;
  border-radius: 8px;
  position: relative;
  box-shadow: 6px 6px 9px 0px rgba(0, 0, 0, 0.1490196078);
  z-index: 10;
}
.group-section .group-row > .row {
  align-items: center;
}
.group-section .group-row > .row .move {
  display: inline-flex;
  margin-right: 6px;
}
.group-section .group-row > .row .group-name {
  padding-top: 3px;
  display: inline-flex;
  flex-direction: column;
}
.group-section .group-row > .row .group-name .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-section .tickets .ticket-row {
  background-color: var(--bs-lt-black);
  margin-top: 0px;
  margin-right: 25px;
  margin-left: 25px;
  border-top: 1px solid var(--bs-black);
  margin-bottom: 1px;
}
@media (max-width: 576px) {
  .group-section .tickets .ticket-row {
    margin-right: 18px;
    margin-left: 18px;
  }
}
.group-section .tickets .ticket-row:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top: initial;
}
.group-section .tickets .ticket-row:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.group-section.coupon-section {
  font-size: 14px;
}
@media (max-width: 991px) {
  .group-section.coupon-section {
    font-size: 16px;
  }
}
.group-section.coupon-section .group-name {
  width: 97%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group-section.coupon-section .group-name .title {
  display: block !important;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyContainer .child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.ticket-row {
  background-color: var(--bs-lt-black);
  padding: 13px 21px;
  border-radius: 8px;
  position: relative;
}
.ticket-row > .row {
  align-items: center;
}
.ticket-row > .row .move {
  display: inline-flex;
  margin-right: 10px;
}
.ticket-row > .row .group-name {
  padding-top: 3px;
  display: inline-flex;
  border-left: 2px dashed #202020;
  padding-left: 10px;
  line-height: 1em;
  flex-direction: column;
}
.ticket-row > .row .group-name .title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ticket-row > .row .group-name small {
  margin-top: 5px;
  display: block;
  font-size: 0.88em;
  line-height: 1.5;
}
.ticket-row:before, .ticket-row:after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 28px;
  width: 26px;
  border-radius: 50%;
  background: #1e1e1e;
  top: 50%;
  transform: translateY(-50%);
}
.ticket-row:before {
  left: -15px;
  right: initial;
}
.ticket-row:after {
  left: initial;
  right: -15px;
}

.event-block {
  min-height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
}
@media (max-width: 576px) {
  .event-block {
    font-size: 0.9em;
  }
}
.event-block .background-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 2;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}
.event-block .background-image img {
  max-height: 250px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  border-radius: 12px;
}
.event-block .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(30, 30, 30, 0.45) 0%, var(--bs-black) 100%);
  width: 100%;
  height: 100%;
  z-index: 3;
}
.event-block .date {
  z-index: 5;
  color: white;
  position: relative;
  display: flex;
  justify-content: end;
}
.event-block .date .slab {
  background: #000;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 0.75em;
  text-align: center;
}
.event-block .date .slab .day {
  font-size: 1.7em;
  line-height: 1em;
}
.event-block .date .slab .month {
  line-height: 1em;
}
.event-block .content {
  z-index: 5;
  color: white;
  position: relative;
  font-size: 0.9em;
}
.event-block .content .organizer .fa-stack {
  font-size: 0.7em;
  margin-top: -4px;
  color: var(--bs-primary);
}
.event-block .content .organizer .fa-stack .fa-check {
  padding-top: 1px;
}
.event-block .content .event-name {
  font-size: 1.4em;
  font-weight: 700;
}
.event-block.publish {
  margin-top: 24px;
}
.event-block.publish:hover div, .event-block.publish:focus div, .event-block.publish:active div {
  color: #fff;
}
.event-block.publish:not(:first-of-type) {
  margin-top: 30px;
}

.sales-block {
  display: flex;
  min-height: 130px;
  align-items: center;
  background: var(--bs-redwood);
  border-radius: 10px;
  padding: 12px;
}
@media (max-width: 576px) {
  .sales-block {
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.sales-block .icon {
  display: flex;
  align-items: center;
  width: 70px;
  font-size: 35px;
  justify-content: center;
  transition: 0.2s ease-in-out;
}
@media (max-width: 576px) {
  .sales-block .icon {
    width: 60px;
    font-size: 30px;
    margin-bottom: 10px;
  }
}
.sales-block .content {
  display: flex;
  flex-direction: column;
}
.sales-block .content .title {
  font-weight: 700;
}
.sales-block .content .stat {
  font-size: 26px;
}
@media (max-width: 576px) {
  .sales-block .content .stat {
    font-size: 13px;
  }
}
.sales-block:hover .icon, .sales-block:focus .icon, .sales-block:active .icon {
  scale: 1.005;
  font-size: 38px;
  transform: rotate(3deg) scale(1.2);
}

.overview-events {
  position: relative;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 10px;
  margin-top: 7px;
}
.overview-events .overview-block {
  display: block;
  border-radius: 10px;
  box-shadow: 3px 3px 5px var(--bs-dk-black);
  transition: 0.2s ease-in-out;
  cursor: pointer;
  scale: 1;
  color: inherit;
  margin-right: 20px;
  max-width: 370px;
  min-width: 370px;
}
@media (max-width: 576px) {
  .overview-events .overview-block {
    max-width: 300px;
    min-width: 80vw;
  }
}
.overview-events .overview-block:last-child {
  margin-right: 0;
  max-width: initial;
  min-width: initial;
}
.overview-events .overview-block .background-image img {
  min-height: 100%;
}
.overview-events .overview-block .content .event-name {
  white-space: initial;
  line-height: 1.2;
}
.overview-events .overview-block .overlay {
  background: var(--bs-black);
  opacity: 0.75;
  transition: 0.2s ease-in-out;
}
.overview-events .overview-block:hover, .overview-events .overview-block:focus, .overview-events .overview-block:active, .overview-events .overview-block.active {
  box-shadow: 6px 6px 10px var(--bs-dk-black);
  scale: 1.01;
  color: inherit;
}
.overview-events .overview-block:hover *, .overview-events .overview-block:focus *, .overview-events .overview-block:active *, .overview-events .overview-block.active * {
  color: inherit;
}
.overview-events .overview-block:hover .fa-check, .overview-events .overview-block:focus .fa-check, .overview-events .overview-block:active .fa-check, .overview-events .overview-block.active .fa-check {
  color: var(--bs-white);
}
.overview-events .overview-block:hover .overlay, .overview-events .overview-block:focus .overlay, .overview-events .overview-block:active .overlay, .overview-events .overview-block.active .overlay {
  background: var(--bs-black);
  opacity: 0.48;
}
.overview-events .overview-block .create-event {
  justify-content: center;
  width: 100%;
  padding: 0 40px;
  font-size: 1.2em;
}
.overview-events .overview-block .create-event .overlay {
  background: var(--bs-lt-red);
  transition: 0.2s ease-in-out;
}
.overview-events .overview-block .create-event .text {
  margin-top: 7px;
  text-align: center;
}
.overview-events .overview-block .create-event:hover .overlay, .overview-events .overview-block .create-event:focus .overlay, .overview-events .overview-block .create-event :active .overlay {
  background: var(--bs-red);
  opacity: 0.75;
}

.modal .modal-content {
  background-color: var(--bs-black);
  border-radius: 15px;
}
.modal .modal-content .modal-header {
  border-bottom-color: var(--bs-dk-grey);
}
.modal .modal-content .modal-header h1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
@media (max-width: 576px) {
  .modal .modal-content .modal-header {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
.modal .modal-content .modal-header .close {
  font-size: 25px;
  display: flex;
}
.modal .modal-content .modal-footer {
  border-top-color: var(--bs-dk-grey);
}
@media (max-width: 576px) {
  .modal .modal-content .modal-footer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.attendee-block-container .attendee-block {
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--bs-lt-black);
}
.attendee-block-container .attendee-block .heading {
  margin-bottom: -9px;
}
.attendee-block-container > :first-child {
  margin-top: 18px;
}
.attendee-block-container > :last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.payment-radio-container .payment-radio {
  display: block;
  padding: 18px;
  border: 1px solid var(--bs-dk-grey);
  border-bottom: 0;
  padding-bottom: 12px;
}
.payment-radio-container .payment-radio .form-check {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  padding: 0;
}
.payment-radio-container .payment-radio .form-check-input {
  margin-top: 0px;
}
.payment-radio-container > :first-child {
  margin-top: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.payment-radio-container > :last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom: 1px solid var(--bs-dk-grey);
}

.schedule-tabs {
  position: relative;
  list-style: none;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 10px;
  margin-top: 7px;
  cursor: grab;
  margin-top: 30px;
  display: flex;
  flex-wrap: initial;
  border: 0;
  /***** SCROLL BAR ****/
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.schedule-tabs::-webkit-scrollbar {
  height: 5px;
  border-radius: 7px;
}
.schedule-tabs::-webkit-scrollbar-track {
  background: var(--bs-grey);
  border-radius: 5px;
}
.schedule-tabs::-webkit-scrollbar-thumb {
  background: var(--bs-dk-grey);
  border-radius: 5px;
}
.schedule-tabs::-webkit-scrollbar-thumb:hover {
  background: var(--bs-black);
}
.schedule-tabs .nav-item {
  display: block;
  margin-right: 8px;
}
.schedule-tabs .nav-item .nav-link {
  color: var(--bs-white);
  line-height: 1.2em;
  border-radius: 10px;
  background-color: var(--bs-dk-black);
  margin-top: 3px;
  padding: 0;
  height: 86px;
  width: 86px;
  max-height: 86px;
  max-width: 86px;
  padding-right: 1px;
  padding-top: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.schedule-tabs .nav-item .nav-link .day {
  display: block;
  font-size: 22px;
  font-weight: 700;
}
.schedule-tabs .nav-item .nav-link .month {
  font-size: 16px;
  display: block;
}
.schedule-tabs .nav-item .nav-link .analytics-block .icon {
  font-size: 1.2em;
  margin-bottom: 5px;
}
.schedule-tabs .nav-item .nav-link .analytics-block .title {
  font-weight: 700;
  font-size: 0.7em;
}
.schedule-tabs .nav-item .nav-link.active {
  color: var(--bs-black);
  background-color: var(--bs-lt-red);
  border-color: var(--bs-lt-red);
}
.schedule-tabs .nav-item .nav-link:hover, .schedule-tabs .nav-item .nav-link:focus {
  color: var(--bs-black);
  background-color: var(--bs-lt-red);
  border-color: var(--bs-dk-grey);
}

.ticket-modal-tab {
  border-bottom-color: var(--bs-lt-red);
}
.ticket-modal-tab .nav-link {
  color: var(--bs-white);
  margin-right: 6px;
  border-color: var(--bs-lt-red);
  font-weight: 700;
}
.ticket-modal-tab .nav-link:hover, .ticket-modal-tab .nav-link:focus, .ticket-modal-tab .nav-link:active, .ticket-modal-tab .nav-link.active {
  background-color: var(--bs-lt-red);
  color: var(--bs-black);
  border-color: var(--bs-lt-red);
}

.tab-pane {
  margin-top: 20px;
}

.item-profile {
  background-color: var(--bs-black);
  border-radius: 12px;
  padding: 15px 7px;
  display: flex;
  color: var(--bs-white) !important;
}
.item-profile:hover, .item-profile:focus, .item-profile:active {
  background-color: var(--bs-dk-black);
}
.item-profile:hover .name, .item-profile:hover .handle, .item-profile:focus .name, .item-profile:focus .handle, .item-profile:active .name, .item-profile:active .handle {
  color: var(--bs-white);
}
.item-profile .icon {
  color: var(--bs-lt-red);
}
.item-profile .icon .d-block {
  line-height: 1;
}
.item-profile .profile-image {
  width: 60px;
  padding-right: 11px;
}
.item-profile .profile-image img {
  border-radius: 50%;
}
.item-profile .profile-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.item-profile .profile-content .handle {
  font-size: 0.85em;
}
.item-profile.dark {
  background-color: var(--bs-dk-black);
}
.item-profile.dark:hover, .item-profile.dark:focus, .item-profile.dark:active {
  background-color: #000;
}

.order-details .title {
  font-size: 0.9em;
  color: var(--bs-grey);
}
.order-details .content {
  font-weight: 700;
  font-size: 1.1em;
}
.order-details .content .fa-stack {
  font-size: 0.55em;
  margin-top: -4px;
  color: var(--bs-primary);
}
.order-details .content small {
  display: block;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 1.15;
  color: var(--bs-lt-grey);
}
.order-details .notes-author .name {
  font-weight: 700;
}
.order-details .notes-author .name small {
  color: var(--bs-grey);
  font-weight: 400;
  font-size: 0.8em;
}
.order-details .notes-author p {
  color: var(--bs-lt-grey);
  font-weight: 400;
  font-size: 0.9em;
}

.calendar {
  display: flex;
  margin-top: 50px;
}
.calendar .hours-col {
  width: 62px;
  text-align: end;
}
.calendar .hours-col .hour {
  height: 50px;
  border-bottom: 1px solid grey;
  position: relative;
  text-transform: uppercase;
  font-size: 11px;
  border-right: 1px solid grey;
}
.calendar .hours-col .hour:first-child {
  height: 30px;
}
.calendar .hours-col .hour span {
  position: absolute;
  bottom: -11px;
  right: 10px;
  z-index: 5;
  background-color: var(--bs-black);
  width: calc(100% - 10px);
  margin-left: 10px;
  padding-right: 7px;
}
.calendar .entries-col {
  height: 100%;
  width: 100%;
  position: relative;
}
.calendar .entries-col .hour-space {
  height: 50px;
  border-bottom: 1px solid grey;
}
.calendar .entries-col .hour-space:first-child {
  height: 30px;
}
.calendar .entries-col .clickable-entry {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.calendar .entries-col .rooms {
  position: absolute;
  top: 30px;
  left: 0;
  width: 100%;
  font-size: 11px;
  line-height: 1.3em;
  height: 100%;
}
.calendar .entries-col .rooms .room-names {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-top: -70px;
  position: sticky;
  top: 0;
  background-color: var(--bs-black);
  z-index: 6;
  padding: 15px 0;
}
.calendar .entries-col .rooms .entry-row {
  height: 100%;
}
.calendar .entries-col .rooms .entry-row .col {
  border-right: 1px solid grey;
}
.calendar .entries-col .rooms .entry-row .col:last-child {
  border-right: none;
}
.calendar .entries-col .rooms .entries-container {
  position: relative;
}
.calendar .entries-col .rooms .entries-container .entry-block {
  position: absolute;
  width: 100%;
  background-color: var(--bs-lt-black);
  padding: 10px;
  border-radius: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  overflow: hidden;
  z-index: 10;
}
.calendar .entries-col .rooms .entries-container .entry-block .title {
  font-weight: 700;
}
@media (max-width: 576px) {
  .calendar .entries-col .rooms .entries-container .entry-block .time {
    font-size: 9px;
  }
}

.pricing-container {
  position: relative;
}
.pricing-container .pricing-grid {
  position: relative;
  z-index: 9;
  padding-top: 100px;
  padding-bottom: 80px;
  pointer-events: none;
}
.pricing-container .pricing-grid .info-row {
  border-bottom: 1px solid rgba(221, 221, 221, 0.7098039216);
  padding-bottom: 5px;
  margin-bottom: 18px;
}
@media (max-width: 576px) {
  .pricing-container .pricing-grid .info-row {
    font-size: 12px;
  }
}
.pricing-container .pricing-grid .info-row .info {
  pointer-events: all;
}
.pricing-container .pricing-grid .info-row .fa-x {
  color: rgba(255, 255, 255, 0.3098039216);
}
.pricing-container .pricing-options {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pricing-container .pricing-options .option {
  background-color: var(--bs-lt-black);
  min-height: 100%;
  border-radius: 12px;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  transition: 0.18s ease-in-out;
}
.pricing-container .pricing-options .option.selected, .pricing-container .pricing-options .option:hover, .pricing-container .pricing-options .option:focus, .pricing-container .pricing-options .option:active {
  background-color: #bf4848;
  color: var(--bs-white);
}
.pricing-container .pricing-options .option .title {
  padding-top: 32px;
  padding-bottom: 12px;
  font-weight: 700;
  font-size: 0.9em;
  text-align: center;
  border-bottom: 2px dashed var(--bs-lt-grey);
}
.pricing-container .pricing-options .option:before, .pricing-container .pricing-options .option:after {
  content: "";
  display: inline-block;
  position: absolute;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  background: #1e1e1e;
  left: 50%;
  transform: translateX(-50%);
}
.pricing-container .pricing-options .option:before {
  top: -18px;
  left: 50%;
  right: initial;
}
.pricing-container .pricing-options .option:after {
  bottom: -18px;
  left: 50%;
  right: initial;
}
.pricing-container .pricing-options .option .bottom {
  border-bottom: 2px dashed var(--bs-lt-grey);
  content: "";
  height: 2px;
  position: absolute;
  bottom: 52px;
  left: 5px;
  width: calc(100% - 10px);
}

input[type=date].form-control, input[type=time].form-control {
  border: none;
  box-sizing: border-box;
  outline: 0;
  position: relative;
  width: 100%;
}

input[type=date]::-webkit-calendar-picker-indicator, input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  bottom: 0;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.progress-container {
  width: 100%;
  margin: 1.5em auto;
}
.progress-container .progress-steps {
  counter-reset: step;
  display: flex;
  padding: 0;
  margin: 0;
}
.progress-container .progress-steps li {
  list-style-type: none;
  width: 25%;
  font-size: 0.7em;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: var(--bs-grey);
  font-weight: 700;
}
.progress-container .progress-steps li:before {
  width: 2.7em;
  height: 2.7em;
  content: counter(step);
  counter-increment: step;
  line-height: 2.5em;
  border: 2px solid var(--bs-grey);
  display: block;
  text-align: center;
  margin: 0 auto 0.7em auto;
  border-radius: 50%;
  background-color: var(--bs-black);
  font-weight: 700;
}
.progress-container .progress-steps li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: var(--bs-grey);
  top: 1.3em;
  left: -50%;
  z-index: -1;
}
.progress-container .progress-steps li:first-child:after {
  content: none;
}
.progress-container .progress-steps li.complete {
  color: var(--bs-red);
}
.progress-container .progress-steps li.complete:before {
  background: var(--bs-red);
  border-color: var(--bs-red);
  color: var(--bs-black);
}
.progress-container .progress-steps li.complete:after {
  background-color: var(--bs-red);
}

.progress-steps li.active {
  color: var(--bs-red);
}
.progress-steps li.active:before {
  border-color: var(--bs-red);
}
.progress-steps li.active:after {
  background-color: var(--bs-red);
}

.home-search-filters {
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
.home-search-filters .search {
  max-width: 270px;
}
.home-search-filters .mobile-search {
  width: 100%;
}
@media (max-width: 991px) {
  .home-search-filters {
    flex-direction: column;
    gap: 0px;
  }
  .home-search-filters .search {
    max-width: initial;
  }
  .home-search-filters .mobile-search {
    display: flex;
    width: 100%;
  }
  .home-search-filters .mobile-search .btn-group .btn {
    white-space: nowrap;
    padding: 0 15px;
  }
}

.row-blocks {
  position: relative;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 10px;
  margin-top: 7px;
  display: flex;
  cursor: grab;
  /***** SCROLL BAR ****/
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.row-blocks::-webkit-scrollbar {
  height: 5px;
  border-radius: 7px;
}
.row-blocks::-webkit-scrollbar-track {
  background: var(--bs-grey);
  border-radius: 5px;
}
.row-blocks::-webkit-scrollbar-thumb {
  background: var(--bs-dk-grey);
  border-radius: 5px;
}
.row-blocks::-webkit-scrollbar-thumb:hover {
  background: var(--bs-black);
}
.row-blocks .block {
  display: block;
  border-radius: 10px;
  transition: 0.15s ease-in-out;
  scale: 1;
  color: inherit;
  margin-right: 20px;
  max-width: 260px;
  min-width: 260px;
  white-space: initial;
  margin-top: 10px;
}
@media (max-width: 991px) {
  .row-blocks .block {
    max-width: 235px;
    min-width: 235px;
  }
}
.row-blocks .block .profile {
  display: flex;
  justify-content: center;
}
.row-blocks .block .profile img {
  border-radius: 50%;
  width: 80px;
}
.row-blocks .block .bio {
  font-size: 0.9em;
  color: var(--bs-grey);
}
.row-blocks .block .cta .following {
  background-color: var(--bs-black);
  color: var(--bs-white);
}

.notices {
  margin-top: 10px;
}
.notices .received-block {
  display: flex;
  padding: 35px 15px;
  border-bottom: 1px solid var(--bs-dk-grey);
}
@media (max-width: 576px) {
  .notices .received-block {
    padding: 28px 10px;
    font-size: 0.95em;
  }
}
.notices .received-block .profile img {
  width: 70px;
  border-radius: 50%;
  margin-right: 15px;
}
@media (max-width: 576px) {
  .notices .received-block .profile img {
    width: 50px;
  }
}
.notices .received-block .content {
  width: 100%;
}
.notices .received-block .content .ticket-name {
  font-size: 1.05em;
}
.notices .received-block .content .ticket-name small {
  font-size: 0.8em;
}
.notices .received-block .icons {
  display: flex;
  margin-top: -2px;
  margin-left: -6px;
}
.notices .received-block .icons .message {
  color: var(--bs-white);
  padding: 8px;
  margin-bottom: -15px;
}
.notices .received-block .icons .message svg.fa-messages {
  color: #007bff !important;
}
.notices .received-block .icons .message svg.fa-heart {
  color: #ff0000 !important;
}
.notices .received-block .icons .message span {
  font-size: 0.95em;
}
.notices .received-block .time {
  width: 100px;
  text-align: right;
}

.order-info .organizer {
  display: flex;
  margin-top: 28px;
}
.order-info .organizer .profile {
  display: flex;
  align-items: center;
  width: 100%;
}
.order-info .organizer .profile img {
  width: 70px;
  margin-right: 8px;
  border-radius: 50%;
}
@media (max-width: 576px) {
  .order-info .organizer .profile img {
    width: 50px;
  }
}
.order-info .organizer .profile .name {
  display: inline-block;
  font-weight: 700;
}
.order-info .organizer .profile .name .fa-stack {
  font-size: 0.7em;
  margin-top: -4px;
  color: var(--bs-primary);
}
.order-info .organizer .profile .name svg.fa-check {
  padding-top: 1px;
  padding-right: 1px;
}
.order-info .announcement {
  border: 1px solid var(--bs-dk-grey);
  border-radius: 12px;
  padding: 19px 15px 15px 15px;
}
.order-info .announcement p {
  margin: 0;
}
.order-info .tool {
  text-align: center;
  font-size: 1.3em;
}
.order-info .tool .title {
  font-size: 0.8em;
  margin-top: 6px;
  color: var(--bs-white) !important;
}
@media (max-width: 576px) {
  .order-info .tool .title {
    font-size: 0.62em;
  }
}
.order-info .tickets {
  position: relative;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-bottom: 15px;
  margin-bottom: 10px;
  margin-top: 7px;
  display: flex;
  cursor: grab;
  margin-top: 30px;
  display: flex;
  /***** SCROLL BAR ****/
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.order-info .tickets::-webkit-scrollbar {
  height: 5px;
  border-radius: 7px;
}
.order-info .tickets::-webkit-scrollbar-track {
  background: var(--bs-grey);
  border-radius: 5px;
}
.order-info .tickets::-webkit-scrollbar-thumb {
  background: var(--bs-dk-grey);
  border-radius: 5px;
}
.order-info .tickets::-webkit-scrollbar-thumb:hover {
  background: var(--bs-black);
}
.order-info .tickets .ticket {
  background-color: var(--bs-dk-black);
  padding: 40px 20px 40px 20px;
  border-radius: 12px;
  margin-right: 20px;
  white-space: initial;
  min-width: 280px;
  max-width: 280px;
  font-size: 0.9em;
  position: relative;
  overflow: hidden;
}
.order-info .tickets .ticket .qr {
  text-align: center;
}
.order-info .tickets .ticket .qr img {
  width: 140px;
  border-radius: 5px;
}
.order-info .tickets .ticket h5 {
  font-size: 1.2em;
}
.order-info .tickets .ticket .ticket-name {
  line-height: 1.2em;
}
.order-info .tickets .ticket:before, .order-info .tickets .ticket:after {
  content: "";
  background: var(--bs-black);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
}
.order-info .tickets .ticket:before {
  top: -20px;
}
.order-info .tickets .ticket:after {
  bottom: -20px;
}
.order-info .tickets .ticket hr {
  border-style: dashed;
}
.order-info .event-info {
  margin-top: 30px;
}
.order-info .event-info > * {
  margin-top: 15px;
}
.order-info .event-info > *:first-child {
  margin-top: 0;
}

.user-profile .account {
  text-align: center;
}
.user-profile .account img {
  margin-bottom: 20px;
  border-radius: 50%;
  width: 120px;
}
.user-profile .account .handle {
  margin-bottom: 10px;
}
.user-profile .account .bio {
  font-size: 0.88em;
  max-width: 400px;
  margin: 0 auto;
}
.user-profile .user-tickets {
  display: block;
  width: 100%;
  border-top: 1px solid var(--bs-dk-grey);
  border-bottom: 1px solid var(--bs-dk-grey);
  text-align: center;
  margin-top: 20px;
  padding: 20px 0;
  font-size: 1.05em;
  color: var(--bs-white) !important;
}

.navbar {
  position: fixed;
  z-index: 10;
  padding: 10px;
  display: flex;
  width: 100%;
  background-color: rgba(15, 15, 15, 0.6745098039);
}
@media (max-width: 576px) {
  .navbar {
    background-color: rgba(15, 15, 15, 0.8745098039);
  }
}
.navbar .navbar-logo {
  display: flex;
  text-decoration: none;
}
.navbar .navbar-logo img {
  max-width: 50px;
}
.navbar .navbar-logo .name {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 5px;
  margin-left: 10px;
  color: var(--bs-white);
}
.navbar .main-menu {
  display: flex;
  padding: 0;
  margin: 0;
  margin-left: auto;
  list-style: none;
}
.navbar .main-menu li {
  display: flex;
  align-items: center;
}
.navbar .main-menu li a {
  margin-right: 15px;
  color: var(--bs-white);
  text-decoration: none;
}
.navbar .main-menu li a.btn {
  padding: 10px 15px;
}
@media (max-width: 576px) {
  .navbar .main-menu {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    height: 100vh;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: -1;
    transition: 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
  }
  .navbar .main-menu.show {
    opacity: 1;
    visibility: visible;
  }
  .navbar .main-menu li {
    margin-bottom: 15px;
  }
  .navbar .main-menu .btn {
    background: none;
    padding: initial !important;
    border: none;
  }
  .navbar .main-menu a {
    font-size: 25px;
    color: var(--bs-white);
    font-weight: 700;
    margin-right: 0 !important;
  }
}

.hamburgerEvent {
  display: none;
  cursor: pointer;
}
@media (max-width: 576px) {
  .hamburgerEvent {
    display: inline-block;
  }
}
.hamburgerEvent .bar-line {
  border-radius: 10px;
}
.hamburgerEvent .bar1, .hamburgerEvent .bar2, .hamburgerEvent .bar3 {
  width: 27px;
  height: 4px;
  background-color: #fff;
  margin: 5px 0;
  transition: 0.2s ease-in-out;
  margin-left: auto;
}
.hamburgerEvent .bar1 {
  width: 27px;
}
.hamburgerEvent .bar2 {
  width: 21px;
}
.hamburgerEvent .bar3 {
  width: 24px;
}
.hamburgerEvent.change .bar1 {
  width: 27px;
  transform: translate(0, 9px) rotate(135deg);
}
.hamburgerEvent.change .bar2 {
  width: 27px;
  opacity: 0;
}
.hamburgerEvent.change .bar3 {
  width: 27px;
  transform: translate(0, -9px) rotate(-135deg);
}

.hero {
  display: flex;
  align-items: end;
  overflow: hidden;
  position: relative;
  min-height: 86vh;
}
@media (max-width: 991px) {
  .hero {
    padding-top: 82px;
    padding-bottom: 50px;
  }
}
.hero .background-image {
  position: absolute;
  top: 50%;
  left: 50%;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}
.hero .background-image img {
  min-height: 86vh;
}
.hero .overlay {
  background: rgb(33, 33, 33);
  background: linear-gradient(0deg, rgb(30, 30, 30) 10%, rgba(30, 30, 30, 0.45) 100%);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.hero .container {
  display: flex;
  position: relative;
}
@media (min-width: 991px) {
  .hero .container {
    max-width: initial;
  }
}
@media (max-width: 991px) {
  .hero .container {
    flex-direction: column;
  }
  .hero .container .slider {
    order: 0;
    margin-bottom: 30px;
  }
  .hero .container .content {
    order: 1;
  }
}
.hero .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
  margin-right: 50px;
  z-index: 3;
  padding: 80px 100px;
}
@media (max-width: 991px) {
  .hero .content {
    width: 100%;
    margin-right: 0px;
    min-width: initial;
    padding: initial;
  }
}
.hero .content .tagline {
  font-size: 0.85em;
  font-weight: 700;
  color: var(--bs-lt-grey);
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.hero .slider {
  width: 100%;
  z-index: 4;
}
.hero .slider img {
  border-radius: 15px;
  height: 450px;
}
@media (max-width: 576px) {
  .hero .slider img {
    height: 275px;
  }
}
.hero.single-event {
  padding: 100px 0 20px 0;
  min-height: initial;
}
.hero.single-event .background-images img {
  filter: blur(10px);
}
.hero.single-event .container {
  flex-direction: column;
}
.hero.single-event .container .content {
  margin-right: 0;
  display: block;
  padding: 0;
}
.hero.single-event .organizer {
  display: block;
  margin-top: 12px;
  margin-bottom: 5px;
  color: white;
  text-decoration: none;
}
.hero.single-event .organizer .fa-stack {
  font-size: 0.6em;
  margin-top: -4px;
  color: var(--bs-primary);
}
.hero.single-event .date, .hero.single-event .location, .hero.single-event .price {
  display: block;
  margin-bottom: 7px;
}
@media (max-width: 576px) {
  .hero.single-event {
    padding-top: 68px;
  }
  .hero.single-event .container {
    padding: 0;
  }
  .hero.single-event .container .content {
    padding: 0 12px;
  }
  .hero.single-event .slider img {
    border-radius: initial;
  }
}

.single-event .container, .single-event-body .container {
  max-width: 800px;
}

.login-hero {
  min-height: 100vh;
  display: flex;
  /**** THANK YOU SCREEN ****/
}
.login-hero .image-background {
  width: 55%;
  position: relative;
}
.login-hero .image-background::before {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(-90deg, rgb(30, 30, 30) 0%, rgba(30, 30, 30, 0) 100%);
  margin-left: 1px;
}
.login-hero .image-background img {
  min-height: 100%;
}
.login-hero > .content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  max-width: 500px;
}
.login-hero > .content .login-form {
  width: 100%;
  z-index: 0;
  padding: 30px;
}
@media (max-width: 991px) {
  .login-hero {
    display: block;
  }
  .login-hero .image-background {
    width: 100%;
  }
  .login-hero .image-background img {
    min-height: 38vh;
  }
  .login-hero .image-background::before {
    background: linear-gradient(0deg, rgb(30, 30, 30) 0%, rgba(30, 30, 30, 0) 100%);
    margin-left: 0px;
  }
  .login-hero .content .login-form {
    padding: 10px;
  }
}
.login-hero .or-block {
  margin-bottom: -10px;
  margin-top: 12px;
}
.login-hero.thank-you > .content {
  max-width: 700px;
  align-items: initial;
  padding: 20px 0;
}
.login-hero.thank-you > .content .login-form {
  border-radius: 20px;
  margin-top: 60px;
}
.login-hero.thank-you > .content .login-form .fa-badge-check {
  font-size: 4em;
  margin-bottom: 20px;
}
.login-hero.thank-you > .content .confirmation-content {
  background-color: var(--bs-dk-black);
  padding: 30px 25px;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .login-hero.thank-you > .content .confirmation-content {
    padding: 22px 20px;
  }
}
.login-hero.thank-you > .content .confirmation-content hr {
  border-top-style: dashed;
}
.login-hero.thank-you > .content .confirmation-content a {
  text-decoration: underline;
}

.index-events {
  position: relative;
  list-style: none;
  overflow-x: auto;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-bottom: 20px;
  margin-top: 7px;
}
.index-events .index-block {
  display: block;
  border-radius: 10px;
  box-shadow: 3px 3px 5px var(--bs-dk-black);
  transition: 0.15s ease-in-out;
  scale: 1;
  color: inherit;
  margin-right: 20px;
  text-decoration: none;
}
@media (max-width: 576px) {
  .index-events .index-block {
    max-width: 300px;
    min-width: 80vw;
  }
}
.index-events .index-block:last-child {
  margin-right: 0;
}
.index-events .index-block:hover, .index-events .index-block:focus, .index-events .index-block:active, .index-events .index-block.active {
  box-shadow: 6px 6px 10px var(--bs-dk-black);
  scale: 1.01;
  color: inherit;
}
.index-events .index-block:hover *, .index-events .index-block:focus *, .index-events .index-block:active *, .index-events .index-block.active * {
  color: inherit;
}
.index-events .index-block:hover .fa-check, .index-events .index-block:focus .fa-check, .index-events .index-block:active .fa-check, .index-events .index-block.active .fa-check {
  color: var(--bs-white);
}
.index-events .index-block .background-image img {
  min-height: 100%;
}
.index-events .index-block .content .event-name {
  white-space: initial;
  line-height: 1.2;
}
.index-events .index-block .overlay {
  background: rgb(33, 33, 33);
  background: linear-gradient(0deg, rgb(30, 30, 30) 40%, rgba(30, 30, 30, 0) 100%);
  opacity: 0.9;
}

.ticket-sales .ticket {
  display: flex;
  margin-top: 15px;
  position: relative;
}
.ticket-sales .ticket .ticket-border {
  min-height: 100%;
  min-width: 4px;
  background-image: linear-gradient(45deg, rgba(66, 66, 66, 0.5) 25%, transparent 25%, transparent 50%, rgba(66, 66, 66, 0.5) 50%, rgba(66, 66, 66, 0.5) 75%, transparent 75%, transparent);
  background-color: var(--bs-lt-black);
  background-size: 1rem 1rem;
  animation: animatedPole 3s linear infinite;
  animation-play-state: paused;
  transition: 0.2s ease-in-out;
}
.ticket-sales .ticket.active .ticket-border {
  background-color: var(--bs-red);
  animation-play-state: running;
}
.ticket-sales .ticket .content {
  padding: 15px;
  background: var(--bs-dk-black);
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ticket-sales .ticket .content .header {
  display: flex;
  width: 100%;
  margin-bottom: 18px;
}
.ticket-sales .ticket .content .header .title {
  display: flex;
  align-items: center;
  padding-right: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.1em;
  font-weight: 700;
}
.ticket-sales .ticket .content .header .count {
  display: flex;
  align-items: center;
  width: 170px;
}
.ticket-sales .ticket .content .header .count .dropdown {
  margin: 0;
}
.ticket-sales .ticket .content p {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
}
.ticket-sales .ticket .content .input-block label {
  background: var(--bs-dk-black);
}
.ticket-sales .ticket .content .input-block label:after {
  background: var(--bs-dk-black);
}
.ticket-sales .ticket .content .input-block:focus-within {
  background-color: var(--bs-dk-black);
}
.ticket-sales .ticket .content .input-block:focus-within input,
.ticket-sales .ticket .content .input-block:focus-within textarea {
  background-color: var(--bs-dk-black);
}
.ticket-sales .ticket .content .input-block:focus-within label:after {
  background: var(--bs-dk-black);
}
.ticket-sales .ticket .content .dropdown-menu {
  min-width: initial;
}
.ticket-sales .ticket .content .dropdown-menu .dropdown-links {
  width: 74px;
}
.ticket-sales .ticket .content.active {
  border-color: var(--bs-red);
}
.ticket-sales .ticket::after {
  background-color: var(--bs-black);
  height: 27px;
  width: 27px;
  content: "";
  border-radius: 50%;
  position: absolute;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.ticket-sales .ticket-group {
  margin-top: 20px;
  border-top: 1px solid var(--bs-dk-grey);
  padding-top: 3px;
}
.ticket-sales .ticket-group .group-title {
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: -2px;
}
.ticket-sales .ticket-group .ticket {
  padding-left: 20px;
}
@media (max-width: 576px) {
  .ticket-sales .ticket-group .ticket {
    padding-left: 13px;
  }
}

@keyframes animatedPole {
  0% {
    background-position-x: 2rem;
  }
}
footer {
  margin-top: 20px;
  padding: 50px 0;
}

.ticket-footer {
  position: fixed;
  bottom: 0;
  padding: 15px;
  background: var(--bs-dk-black);
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: 0.15s ease-in-out;
}
.ticket-footer .btn {
  padding: 13px 35px 10px 35px;
}
.ticket-footer.active {
  opacity: 1;
  z-index: 10;
  visibility: visible;
}

.reel {
  position: relative;
  margin-top: 8px;
  border-radius: 20px;
  overflow: hidden;
}
.reel .video-block {
  position: initial;
  top: 0;
  left: 0;
}
.reel .video-block video {
  width: 100%;
}
.reel .thumbnail {
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 9/16;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  transition: 0.2s ease-in-out;
}
.reel .thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
}
.reel .thumbnail .icon {
  z-index: 5;
  font-size: 1.7em;
}
.reel .thumbnail .icon .fa-stack {
  transition: 0.15s ease-in-out;
  color: var(--bs-black);
  margin-right: 4px;
}
.reel .thumbnail .icon .fa-stack .fa-inverse {
  transition: 0.2s ease-in-out;
  color: var(--bs-lt-red);
}
.reel:hover .thumbnail .fa-stack {
  color: transparent;
  scale: 2;
}
.reel:hover .thumbnail .fa-stack .fa-inverse {
  color: var(--bs-black);
}
.reel.active .thumbnail {
  scale: 1.8;
  opacity: 0;
  visibility: hidden;
}/*# sourceMappingURL=styles.css.map */